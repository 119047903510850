import styled from "styled-components";

const Section = styled.section`
  margin-bottom: 0em;
  max-width: 550px;

  @media screen and (min-width: 768px) {
    margin-bottom: 3em;
  }
`;

export default Section;
