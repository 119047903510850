import styled from "styled-components";

export const Description = styled.p`
  font-size: 0.8em;
  color: rgba(16, 35, 66, 0.7);

  a {
    color: rgba(16, 35, 66, 0.7);

    &:hover {
      opacity: 0.5;
    }
  }
`;
